@charset "utf-8";
/* Raymon Chan, 2020-09-29 */

/* Ver 1.00 */
/* Grid Style */

/* Ver 1.01 */
/* Add Text Alignment */
/* align-[direction]-[device] */

/* Ver 1.10 */
/* RC: Standardize syntax pattern
/* [style]-[direction]-[size]-[device] */

/* Ver 1.11 */
/* RC (2020-10-26): Add style rounded corner, add Table of Contents and re-order source code 
/* rounded-[direction]-[size]-[device] */

/* TO DO........ */
/* Ver 1.12 */
/* RC: need to add 1300 - Visibility, display on desktop, tablet and mobile */
/* show-[device] */
/* hide-[device] */
/* inline-[device] */

/* TO DO........ */
/* Ver 1.13 */
/* RC: need to add 1210 - Text Case, upper, lower, title */
/* upper-[device] */
/* lower-[device] */
/* title-[device] */

/* Table of Contents
---------------------
1000 - Grid
  1000 - Row & Columns
  1010 - Margin
  1020 - Padding
1100 - Border
  1100 - Rounded Corner
1200 - Text
  1200 - Text Alignment


/* Style (sorted by alphabet)
---------------------
align = align
col = column
hide = display none
inline = display inline-block
m = margin
p = padding
rounded = rounded corner 
show = display block


/* Direction
---------------------
t = top
r = right
b = bottom
l = left
x = left and right
y = top and bottom
a = all 4 directions 

c = center
m = middle
*/

/* size 
---------------------
xs = extra small
sm = small
md = medium
lg = large
xl = extra large

0 = 0 
1, 2, 3, 4, 5, 6 = numbers
05, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100 = percentage 
*/

/* device
---------------------
dk = Desktop, triggered when browser width >= 1024 
tp = Tablet Portrait, Design for a width of 768px, triggered when browser width 768 ~ 1023 
ml = Mobile Landscape, Design for a width of 480px, triggered when browser width 480 ~ 767  
mp = Mobile Portrait, Design for a width of 320px, triggered when browser width < 479
*/


/* ---------------------------------------------------------------------------------------------------------------------------------------
1000 - Grid
--------------------------------------------------------------------------------------------------------------------------------------- */

/* 1000 - Row & Columns
/* ------------------------------------------------------------ */
.section, .row											{width: 100%;}
.section:after, .row:after   	  		{display: block; clear: both; content: '.'; visibility: hidden; height: 0;}
.section											    	{margin: 0 0 1.5em;}
.row												      	{margin: 0 0 0.5em;}
.col, .col-2, .col-3, .col-4, .col-5, .col-6,
.col-xs, .col-sm, .col-md, .col-lg, .col-xl,
.col-05, .col-10, .col-15, .col-20, .col-25, 
.col-30, .col-35, .col-40, .col-45, .col-50, 
.col-55, .col-60, .col-65, .col-70, .col-75, 
.col-80, .col-85, .col-90, .col-95						{float: left; box-sizing:border-box; -moz-box-sizing:border-box; -webkit-box-sizing:border-box;}

.col-2													{width: 49%;}
.col-3													{width: 32%;}
.col-4													{width: 23.5%;}
.col-5													{width: 18%;}
.col-6													{width: 15%;}
.col-2, .col-3, .col-5, .col-6							{margin-right: 2%;}
.col-4													{margin-right: 2%;}
.col-2:last-child,
.col-3:last-child,
.col-4:last-child,										
.col-5:last-child,
.col-6:last-child										{margin-right: 0%;}

.col-05													{width: 5%;}
.col-10													{width: 10%;}
.col-15													{width: 15%;}
.col-20													{width: 20%;}
.col-25													{width: 25%;}
.col-30													{width: 30%;}
.col-35													{width: 35%;}
.col-40													{width: 40%;}
.col-45													{width: 45%;}
.col-50													{width: 50%;}
.col-55													{width: 55%;}
.col-60													{width: 60%;}
.col-65													{width: 65%;}
.col-70													{width: 70%;}
.col-75													{width: 75%;}
.col-80													{width: 80%;}
.col-85													{width: 85%;}
.col-90													{width: 90%;}
.col-95													{width: 95%;}
.col-xs, .col-sm, .col-md, .col-lg, .col-xl,
.col-05, .col-10, .col-15, .col-20, .col-25,
.col-30, .col-35, .col-40, .col-45, .col-50,
.col-55, .col-60, .col-65, .col-70, .col-75, 
.col-80, .col-85, .col-90, .col-95						{padding-right: 1%;}
.col-xs:last-child, .col-sm:last-child, 
.col-md:last-child, .col-lg:last-child, 
.col-xl:last-child,
.col-05:last-child, .col-10:last-child, 
.col-15:last-child, .col-20:last-child, 
.col-25:last-child, .col-30:last-child, 
.col-35:last-child, .col-40:last-child, 
.col-45:last-child, .col-50:last-child, 
.col-55:last-child, .col-60:last-child, 
.col-65:last-child, .col-70:last-child, 
.col-75:last-child, .col-80:last-child, 
.col-85:last-child, .col-90:last-child, 
.col-95:last-child										{padding-right: 0%;}

/* Tablet (Portrait), Design for a width of 768px */
@media (max-width: 1023px) {	
	
	/* Tablet (Portrait) */
	.col-2-tp, .col-3-tp, .col-4-tp, .col-5-tp, .col-6-tp,
	.col-05-tp, .col-10-tp, .col-15-tp, .col-20-tp, .col-25-tp, 
	.col-30-tp, .col-35-tp, .col-40-tp, .col-45-tp, .col-50-tp, 
	.col-55-tp, .col-60-tp, .col-65-tp, .col-70-tp, .col-75-tp, 
	.col-80-tp, .col-85-tp, .col-90-tp, .col-95-tp, .col-100-tp
															{float: left; box-sizing:border-box; -moz-box-sizing:border-box; -webkit-box-sizing:border-box;}

	.col-2-tp												{width: 49%;}
	.col-3-tp												{width: 32%;}
	.col-4-tp												{width: 23.5%;}
	.col-5-tp												{width: 18%;}
	.col-6-tp												{width: 15%;}
	.col-2-tp, .col-3-tp, .col-5-tp, .col-6-tp				{margin-right: 2%;}
	.col-4-tp												{margin-right: 2%;}
	.col-2-tp:nth-child(even),
	.col-2-tp:last-child,
	.col-3-tp:last-child,
	.col-4-tp:last-child,										
	.col-5-tp:last-child,
	.col-6-tp:last-child									{margin-right: 0%;}
	
	.col-05-tp												{width: 5%;}
	.col-10-tp												{width: 10%;}
	.col-15-tp												{width: 15%;}
	.col-20-tp												{width: 20%;}
	.col-25-tp												{width: 25%;}
	.col-30-tp												{width: 30%;}
	.col-35-tp												{width: 35%;}
	.col-40-tp												{width: 40%;}
	.col-45-tp												{width: 45%;}
	.col-50-tp												{width: 50%;}
	.col-55-tp												{width: 55%;}
	.col-60-tp												{width: 60%;}
	.col-65-tp												{width: 65%;}
	.col-70-tp												{width: 70%;}
	.col-75-tp												{width: 75%;}
	.col-80-tp												{width: 80%;}
	.col-85-tp												{width: 85%;}
	.col-90-tp												{width: 90%;}
	.col-95-tp												{width: 95%;}
	.col-100-tp												{width: 100%;}	
	.col-05-tp, .col-10-tp, .col-15-tp, .col-20-tp, .col-25-tp,
	.col-30-tp, .col-35-tp, .col-40-tp, .col-45-tp, .col-50-tp,
	.col-55-tp, .col-60-tp, .col-65-tp, .col-70-tp, .col-75-tp, 
	.col-80-tp, .col-85-tp, .col-90-tp, .col-95-tp			{padding-right: 1%;}	
	.col-05-tp:last-child, .col-10-tp:last-child, 
	.col-15-tp:last-child, .col-20-tp:last-child, 
	.col-25-tp:last-child, .col-30-tp:last-child, 
	.col-35-tp:last-child, .col-40-tp:last-child, 
	.col-45-tp:last-child, .col-50-tp:last-child, 
	.col-55-tp:last-child, .col-60-tp:last-child, 
	.col-65-tp:last-child, .col-70-tp:last-child, 
	.col-75-tp:last-child, .col-80-tp:last-child, 
	.col-85-tp:last-child, .col-90-tp:last-child, 
	.col-95-tp:last-child									{padding-right: 0%;}		
}

/* Mobile (Landscape), Design for a width of 480px */
@media (max-width: 767px) {
	
	/* Default Desktop */
	.col-2, .col-3, .col-4, .col-5,  
	.col-05, .col-10, .col-15, .col-20, .col-25,
	.col-30, .col-35, .col-40, .col-45, .col-50,
	.col-55, .col-60, .col-65, .col-70, .col-75, 
	.col-80, .col-85, .col-90, .col-95						{float: none; width: 100%; margin: 0 0 0.5em; padding-right: 0px;}
	
	.col-6													{width: 32%;}
	.col-6													{margin-right: 2%;}
	.col-6:nth-child(3),	
	.col-6:last-child										{margin-right: 0%;}		
		
	/* Mobile Landscape */
	.col-2-ml, .col-3-ml, .col-4-ml, .col-5-ml, .col-6-ml,
	.col-05-ml, .col-10-ml, .col-15-ml, .col-20-ml, .col-25-ml, 
	.col-30-ml, .col-35-ml, .col-40-ml, .col-45-ml, .col-50-ml, 
	.col-55-ml, .col-60-ml, .col-65-ml, .col-70-ml, .col-75-ml, 
	.col-80-ml, .col-85-ml, .col-90-ml, .col-95-ml, .col-100-ml			
															{float: left; box-sizing:border-box; -moz-box-sizing:border-box; -webkit-box-sizing:border-box;}
	
	.col-2-ml												{width: 49%;}
	.col-3-ml												{width: 32%;}
	.col-4-ml												{width: 23.5%;}
	.col-5-ml												{width: 18%;}
	.col-6-ml												{width: 15%;}
	.col-2-ml, .col-3-ml, .col-5-ml, .col-6-ml				{margin-right: 2%;}
	.col-4-ml												{margin-right: 2%;}
	.col-2-ml:nth-child(even),
	.col-2-ml:last-child,
	.col-3-ml:last-child,
	.col-4-ml:last-child,										
	.col-5-ml:last-child,
	.col-6-ml:last-child									{margin-right: 0%;}
	
	.col-05-ml												{width: 5%;}
	.col-10-ml												{width: 10%;}
	.col-15-ml												{width: 15%;}
	.col-20-ml												{width: 20%;}
	.col-25-ml												{width: 25%;}
	.col-30-ml												{width: 30%;}
	.col-35-ml												{width: 35%;}
	.col-40-ml												{width: 40%;}
	.col-45-ml												{width: 45%;}
	.col-50-ml												{width: 50%;}
	.col-55-ml												{width: 55%;}
	.col-60-ml												{width: 60%;}
	.col-65-ml												{width: 65%;}
	.col-70-ml												{width: 70%;}
	.col-75-ml												{width: 75%;}
	.col-80-ml												{width: 80%;}
	.col-85-ml												{width: 85%;}
	.col-90-ml												{width: 90%;}
	.col-95-ml												{width: 95%;}
	.col-100-ml												{width: 100%;}	
	.col-05-ml, .col-10-ml, .col-15-ml, .col-20-ml, .col-25-ml,
	.col-30-ml, .col-35-ml, .col-40-ml, .col-45-ml, .col-50-ml,
	.col-55-ml, .col-60-ml, .col-65-ml, .col-70-ml, .col-75-ml, 
	.col-80-ml, .col-85-ml, .col-90-ml, .col-95-ml			{padding-right: 1%;}	
	.col-05-ml:last-child, .col-10-ml:last-child, 
	.col-15-ml:last-child, .col-20-ml:last-child, 
	.col-25-ml:last-child, .col-30-ml:last-child, 
	.col-35-ml:last-child, .col-40-ml:last-child, 
	.col-45-ml:last-child, .col-50-ml:last-child, 
	.col-55-ml:last-child, .col-60-ml:last-child, 
	.col-65-ml:last-child, .col-70-ml:last-child, 
	.col-75-ml:last-child, .col-80-ml:last-child, 
	.col-85-ml:last-child, .col-90-ml:last-child, 
	.col-95-ml:last-child									{padding-right: 0%;}	


}

/* Mobile (Portrait), Design for a width of 320px */
@media (max-width: 479px) {

	/* Default Desktop */	
	.col-6												{float: none; width: 100%; margin: 0 0 0.5em; padding-right: 0px;}	
	
	/* Mobile Protrait */
	.col-2-mp, .col-3-mp, .col-4-mp, .col-5-mp, .col-6-mp,
	.col-05-mp, .col-10-mp, .col-15-mp, .col-20-mp, .col-25-mp, 
	.col-30-mp, .col-35-mp, .col-40-mp, .col-45-mp, .col-50-mp, 
	.col-55-mp, .col-60-mp, .col-65-mp, .col-70-mp, .col-75-mp, 
	.col-80-mp, .col-85-mp, .col-90-mp, .col-95-mp, .col-100-mp		
															{float: left; box-sizing:border-box; -moz-box-sizing:border-box; -webkit-box-sizing:border-box;}
	
	.col-2-mp												{width: 49%;}
	.col-3-mp												{width: 32%;}
	.col-4-mp												{width: 23.5%;}
	.col-5-mp												{width: 18%;}
	.col-6-mp												{width: 15%;}
	.col-2-mp, .col-3-mp, .col-5-mp, .col-6-mp				{margin-right: 2%;}
	.col-4-mp												{margin-right: 2%;}
	.col-2-mp:nth-child(even),
	.col-2-mp:last-child,
	.col-3-mp:last-child,
	.col-4-mp:last-child,										
	.col-5-mp:last-child,
	.col-6-mp:last-child									{margin-right: 0%;}
	
	.col-05-mp												{width: 5%;}
	.col-10-mp												{width: 10%;}
	.col-15-mp												{width: 15%;}
	.col-20-mp												{width: 20%;}
	.col-25-mp												{width: 25%;}
	.col-30-mp												{width: 30%;}
	.col-35-mp												{width: 35%;}
	.col-40-mp												{width: 40%;}
	.col-45-mp												{width: 45%;}
	.col-50-mp												{width: 50%;}
	.col-55-mp												{width: 55%;}
	.col-60-mp												{width: 60%;}
	.col-65-mp												{width: 65%;}
	.col-70-mp												{width: 70%;}
	.col-75-mp												{width: 75%;}
	.col-80-mp												{width: 80%;}
	.col-85-mp												{width: 85%;}
	.col-90-mp												{width: 90%;}
	.col-95-mp												{width: 95%;}
	.col-100-mp												{width: 100%;}	
	.col-05-mp, .col-10-mp, .col-15-mp, .col-20-mp, .col-25-mp,
	.col-30-mp, .col-35-mp, .col-40-mp, .col-45-mp, .col-50-mp,
	.col-55-mp, .col-60-mp, .col-65-mp, .col-70-mp, .col-75-mp, 
	.col-80-mp, .col-85-mp, .col-90-mp, .col-95-mp			{padding-right: 1%;}	
	.col-05-mp:last-child, .col-10-mp:last-child, 
	.col-15-mp:last-child, .col-20-mp:last-child, 
	.col-25-mp:last-child, .col-30-mp:last-child, 
	.col-35-mp:last-child, .col-40-mp:last-child, 
	.col-45-mp:last-child, .col-50-mp:last-child, 
	.col-55-mp:last-child, .col-60-mp:last-child, 
	.col-65-mp:last-child, .col-70-mp:last-child, 
	.col-75-mp:last-child, .col-80-mp:last-child, 
	.col-85-mp:last-child, .col-90-mp:last-child, 
	.col-95-mp:last-child									{padding-right: 0%;}	
}



/* 1010 - Margin
/* ------------------------------------------------------------ */
.m-a-0													{margin: 0px !important;}
.m-x-0													{margin-left: 0px !important; margin-right: 0px !important;}
.m-y-0													{margin-top: 0px !important; margin-bottom: 0px !important;}
.m-t-0													{margin-top: 0px !important;}
.m-r-0													{margin-right: 0px !important;}
.m-b-0													{margin-bottom: 0px !important;}
.m-l-0													{margin-left: 0px !important;}

.m-a-xs													{margin: 0.3em;}
.m-a-sm													{margin: 0.5em;}
.m-a-md													{margin: 1.0em;}
.m-a-lg													{margin: 1.5em;}
.m-a-xl													{margin: 2.0em;}

.m-x-xs													{margin-left: 0.3em; margin-right: 0.3em;}
.m-x-sm													{margin-left: 0.5em; margin-right: 0.5em;}
.m-x-md													{margin-left: 1.0em; margin-right: 1.0em;}
.m-x-lg													{margin-left: 1.5em; margin-right: 1.5em;}
.m-x-xl													{margin-left: 2.0em; margin-right: 2.0em;}

.m-y-xs													{margin-top: 0.3em; margin-bottom: 0.3em;}
.m-y-sm													{margin-top: 0.5em; margin-bottom: 0.5em;}
.m-y-md													{margin-top: 1.0em; margin-bottom: 1.0em;}
.m-y-lg													{margin-top: 1.5em; margin-bottom: 1.5em;}
.m-y-xl													{margin-top: 2.0em; margin-bottom: 2.0em;}

.m-t-xs													{margin-top: 0.3em;}
.m-t-sm													{margin-top: 0.5em;}
.m-t-md													{margin-top: 1.0em;}
.m-t-lg													{margin-top: 1.5em;}
.m-t-xl													{margin-top: 2.0em;}

.m-r-xs													{margin-right: 0.3em;}
.m-r-sm													{margin-right: 0.5em;}
.m-r-md													{margin-right: 1.0em;}
.m-r-lg													{margin-right: 1.5em;}
.m-r-xl													{margin-right: 2.0em;}

.m-b-xs													{margin-bottom: 0.3em;}
.m-b-sm													{margin-bottom: 0.5em;}
.m-b-md													{margin-bottom: 1.0em;}
.m-b-lg													{margin-bottom: 1.5em;}
.m-b-xl													{margin-bottom: 2.0em;}

.m-l-xs													{margin-left: 0.3em;}
.m-l-sm													{margin-left: 0.5em;}
.m-l-md													{margin-left: 1.0em;}
.m-l-lg													{margin-left: 1.5em;}
.m-l-xl													{margin-left: 2.0em;}


/* Tablet (Portrait), Design for a width of 768px */
@media (max-width: 1023px) {	
	.m-a-0-tp												{margin: 0px !important;}
	.m-x-0-tp												{margin-left: 0px !important; margin-right: 0px !important;}
	.m-y-0-tp												{margin-top: 0px !important; margin-bottom: 0px !important;}
	.m-t-0-tp												{margin-top: 0px !important;}
	.m-r-0-tp												{margin-right: 0px !important;}
	.m-b-0-tp												{margin-bottom: 0px !important;}
	.m-l-0-tp												{margin-left: 0px !important;}

	.m-a-xs-tp												{margin: 0.3em;}
	.m-a-sm-tp												{margin: 0.5em;}
	.m-a-md-tp												{margin: 1.0em;}
	.m-a-lg-tp												{margin: 1.5em;}
	.m-a-xl-tp												{margin: 2.0em;}

	.m-x-xs-tp												{margin-left: 0.3em; margin-right: 0.3em;}
	.m-x-sm-tp												{margin-left: 0.5em; margin-right: 0.5em;}
	.m-x-md-tp												{margin-left: 1.0em; margin-right: 1.0em;}
	.m-x-lg-tp												{margin-left: 1.5em; margin-right: 1.5em;}
	.m-x-xl-tp												{margin-left: 2.0em; margin-right: 2.0em;}

	.m-y-xs-tp												{margin-top: 0.3em; margin-bottom: 0.3em;}
	.m-y-sm-tp												{margin-top: 0.5em; margin-bottom: 0.5em;}
	.m-y-md-tp												{margin-top: 1.0em; margin-bottom: 1.0em;}
	.m-y-lg-tp												{margin-top: 1.5em; margin-bottom: 1.5em;}
	.m-y-xl-tp												{margin-top: 2.0em; margin-bottom: 2.0em;}

	.m-t-xs-tp												{margin-top: 0.3em;}
	.m-t-sm-tp												{margin-top: 0.5em;}
	.m-t-md-tp												{margin-top: 1.0em;}
	.m-t-lg-tp												{margin-top: 1.5em;}
	.m-t-xl-tp												{margin-top: 2.0em;}

	.m-r-xs-tp												{margin-right: 0.3em;}
	.m-r-sm-tp												{margin-right: 0.5em;}
	.m-r-md-tp												{margin-right: 1.0em;}
	.m-r-lg-tp												{margin-right: 1.5em;}
	.m-r-xl-tp												{margin-right: 2.0em;}

	.m-b-xs-tp												{margin-bottom: 0.3em;}
	.m-b-sm-tp												{margin-bottom: 0.5em;}
	.m-b-md-tp												{margin-bottom: 1.0em;}
	.m-b-lg-tp												{margin-bottom: 1.5em;}
	.m-b-xl-tp												{margin-bottom: 2.0em;}

	.m-l-xs-tp												{margin-left: 0.3em;}
	.m-l-sm-tp												{margin-left: 0.5em;}
	.m-l-md-tp												{margin-left: 1.0em;}
	.m-l-lg-tp												{margin-left: 1.5em;}
	.m-l-xl-tp												{margin-left: 2.0em;}	
}

/* Mobile (Landscape), Design for a width of 480px */
@media (max-width: 767px) {
	
	.m-a-0-ml												{margin: 0px !important;}
	.m-x-0-ml												{margin-left: 0px !important; margin-right: 0px !important;}
	.m-y-0-ml												{margin-top: 0px !important; margin-bottom: 0px !important;}
	.m-t-0-ml												{margin-top: 0px !important;}
	.m-r-0-ml												{margin-right: 0px !important;}
	.m-b-0-ml												{margin-bottom: 0px !important;}
	.m-l-0-ml												{margin-left: 0px !important;}

	.m-a-xs-ml												{margin: 0.3em;}
	.m-a-sm-ml												{margin: 0.5em;}
	.m-a-md-ml												{margin: 1.0em;}
	.m-a-lg-ml												{margin: 1.5em;}
	.m-a-xl-ml												{margin: 2.0em;}

	.m-x-xs-ml												{margin-left: 0.3em; margin-right: 0.3em;}
	.m-x-sm-ml												{margin-left: 0.5em; margin-right: 0.5em;}
	.m-x-md-ml												{margin-left: 1.0em; margin-right: 1.0em;}
	.m-x-lg-ml												{margin-left: 1.5em; margin-right: 1.5em;}
	.m-x-xl-ml												{margin-left: 2.0em; margin-right: 2.0em;}

	.m-y-xs-ml												{margin-top: 0.3em; margin-bottom: 0.3em;}
	.m-y-sm-ml												{margin-top: 0.5em; margin-bottom: 0.5em;}
	.m-y-md-ml												{margin-top: 1.0em; margin-bottom: 1.0em;}
	.m-y-lg-ml												{margin-top: 1.5em; margin-bottom: 1.5em;}
	.m-y-xl-ml												{margin-top: 2.0em; margin-bottom: 2.0em;}

	.m-t-xs-ml												{margin-top: 0.3em;}
	.m-t-sm-ml												{margin-top: 0.5em;}
	.m-t-md-ml												{margin-top: 1.0em;}
	.m-t-lg-ml												{margin-top: 1.5em;}
	.m-t-xl-ml												{margin-top: 2.0em;}

	.m-r-xs-ml												{margin-right: 0.3em;}
	.m-r-sm-ml												{margin-right: 0.5em;}
	.m-r-md-ml												{margin-right: 1.0em;}
	.m-r-lg-ml												{margin-right: 1.5em;}
	.m-r-xl-ml												{margin-right: 2.0em;}

	.m-b-xs-ml												{margin-bottom: 0.3em;}
	.m-b-sm-ml												{margin-bottom: 0.5em;}
	.m-b-md-ml												{margin-bottom: 1.0em;}
	.m-b-lg-ml												{margin-bottom: 1.5em;}
	.m-b-xl-ml												{margin-bottom: 2.0em;}

	.m-l-xs-ml												{margin-left: 0.3em;}
	.m-l-sm-ml												{margin-left: 0.5em;}
	.m-l-md-ml												{margin-left: 1.0em;}
	.m-l-lg-ml												{margin-left: 1.5em;}
	.m-l-xl-ml												{margin-left: 2.0em;}	
}

/* Mobile (Portrait), Design for a width of 320px */
@media (max-width: 479px) {

	.m-a-0-mp												{margin: 0px !important;}
	.m-x-0-mp												{margin-left: 0px !important; margin-right: 0px !important;}
	.m-y-0-mp												{margin-top: 0px !important; margin-bottom: 0px !important;}
	.m-t-0-mp												{margin-top: 0px !important;}
	.m-r-0-mp												{margin-right: 0px !important;}
	.m-b-0-mp												{margin-bottom: 0px !important;}
	.m-l-0-mp												{margin-left: 0px !important;}

	.m-a-xs-mp												{margin: 0.3em;}
	.m-a-sm-mp												{margin: 0.5em;}
	.m-a-md-mp												{margin: 1.0em;}
	.m-a-lg-mp												{margin: 1.5em;}
	.m-a-xl-mp												{margin: 2.0em;}

	.m-x-xs-mp												{margin-left: 0.3em; margin-right: 0.3em;}
	.m-x-sm-mp												{margin-left: 0.5em; margin-right: 0.5em;}
	.m-x-md-mp												{margin-left: 1.0em; margin-right: 1.0em;}
	.m-x-lg-mp												{margin-left: 1.5em; margin-right: 1.5em;}
	.m-x-xl-mp												{margin-left: 2.0em; margin-right: 2.0em;}

	.m-y-xs-mp												{margin-top: 0.3em; margin-bottom: 0.3em;}
	.m-y-sm-mp												{margin-top: 0.5em; margin-bottom: 0.5em;}
	.m-y-md-mp												{margin-top: 1.0em; margin-bottom: 1.0em;}
	.m-y-lg-mp												{margin-top: 1.5em; margin-bottom: 1.5em;}
	.m-y-xl-mp												{margin-top: 2.0em; margin-bottom: 2.0em;}

	.m-t-xs-mp												{margin-top: 0.3em;}
	.m-t-sm-mp												{margin-top: 0.5em;}
	.m-t-md-mp												{margin-top: 1.0em;}
	.m-t-lg-mp												{margin-top: 1.5em;}
	.m-t-xl-mp												{margin-top: 2.0em;}

	.m-r-xs-mp												{margin-right: 0.3em;}
	.m-r-sm-mp												{margin-right: 0.5em;}
	.m-r-md-mp												{margin-right: 1.0em;}
	.m-r-lg-mp												{margin-right: 1.5em;}
	.m-r-xl-mp												{margin-right: 2.0em;}

	.m-b-xs-mp												{margin-bottom: 0.3em;}
	.m-b-sm-mp												{margin-bottom: 0.5em;}
	.m-b-md-mp												{margin-bottom: 1.0em;}
	.m-b-lg-mp												{margin-bottom: 1.5em;}
	.m-b-xl-mp												{margin-bottom: 2.0em;}

	.m-l-xs-mp												{margin-left: 0.3em;}
	.m-l-sm-mp												{margin-left: 0.5em;}
	.m-l-md-mp												{margin-left: 1.0em;}
	.m-l-lg-mp												{margin-left: 1.5em;}
	.m-l-xl-mp												{margin-left: 2.0em;}	
}



/* 1020 - Padding
/* ------------------------------------------------------------ */
.p-a-0													{padding: 0px !important;}
.p-x-0													{padding-left: 0px !important; padding-right: 0px !important;}
.p-y-0													{padding-top: 0px !important; padding-bottom: 0px !important;}
.p-t-0													{padding-top: 0px !important;}
.p-r-0													{padding-right: 0px !important;}
.p-b-0													{padding-bottom: 0px !important;}
.p-l-0													{padding-left: 0px !important;}

.p-a-xs													{padding: 0.3em;}
.p-a-sm													{padding: 0.5em;}
.p-a-md													{padding: 1.0em;}
.p-a-lg													{padding: 1.5em;}
.p-a-xl													{padding: 2.0em;}

.p-x-xs													{padding-left: 0.3em; padding-right: 0.3em;}
.p-x-sm													{padding-left: 0.5em; padding-right: 0.5em;}
.p-x-md													{padding-left: 1.0em; padding-right: 1.0em;}
.p-x-lg													{padding-left: 1.5em; padding-right: 1.5em;}
.p-x-xl													{padding-left: 2.0em; padding-right: 2.0em;}

.p-y-xs													{padding-top: 0.3em; padding-bottom: 0.3em;}
.p-y-sm													{padding-top: 0.5em; padding-bottom: 0.5em;}
.p-y-md													{padding-top: 1.0em; padding-bottom: 1.0em;}
.p-y-lg													{padding-top: 1.5em; padding-bottom: 1.5em;}
.p-y-xl													{padding-top: 2.0em; padding-bottom: 2.0em;}

.p-t-xs													{padding-top: 0.3em;}
.p-t-sm													{padding-top: 0.5em;}
.p-t-md													{padding-top: 1.0em;}
.p-t-lg													{padding-top: 1.5em;}
.p-t-xl													{padding-top: 2.0em;}

.p-r-xs													{padding-right: 0.3em;}
.p-r-sm													{padding-right: 0.5em;}
.p-r-md													{padding-right: 1.0em;}
.p-r-lg													{padding-right: 1.5em;}
.p-r-xl													{padding-right: 2.0em;}

.p-b-xs													{padding-bottom: 0.3em;}
.p-b-sm													{padding-bottom: 0.5em;}
.p-b-md													{padding-bottom: 1.0em;}
.p-b-lg													{padding-bottom: 1.5em;}
.p-b-xl													{padding-bottom: 2.0em;}

.p-l-xs													{padding-left: 0.3em;}
.p-l-sm													{padding-left: 0.5em;}
.p-l-md													{padding-left: 1.0em;}
.p-l-lg													{padding-left: 1.5em;}
.p-l-xl													{padding-left: 2.0em;}

/* Tablet (Portrait), Design for a width of 768px */
@media (max-width: 1023px) {	
	.p-a-0-tp											{padding: 0px !important;}
	.p-x-0-tp											{padding-left: 0px !important; padding-right: 0px !important;}
	.p-y-0-tp											{padding-top: 0px !important; padding-bottom: 0px !important;}
	.p-t-0-tp											{padding-top: 0px !important;}
	.p-r-0-tp											{padding-right: 0px !important;}
	.p-b-0-tp											{padding-bottom: 0px !important;}
	.p-l-0-tp											{padding-left: 0px !important;}

	.p-a-xs-tp											{padding: 0.3em;}
	.p-a-sm-tp											{padding: 0.5em;}
	.p-a-md-tp											{padding: 1.0em;}
	.p-a-lg-tp											{padding: 1.5em;}
	.p-a-xl-tp											{padding: 2.0em;}

	.p-x-xs-tp											{padding-left: 0.3em; padding-right: 0.3em;}
	.p-x-sm-tp											{padding-left: 0.5em; padding-right: 0.5em;}
	.p-x-md-tp											{padding-left: 1.0em; padding-right: 1.0em;}
	.p-x-lg-tp											{padding-left: 1.5em; padding-right: 1.5em;}
	.p-x-xl-tp											{padding-left: 2.0em; padding-right: 2.0em;}

	.p-y-xs-tp											{padding-top: 0.3em; padding-bottom: 0.3em;}
	.p-y-sm-tp											{padding-top: 0.5em; padding-bottom: 0.5em;}
	.p-y-md-tp											{padding-top: 1.0em; padding-bottom: 1.0em;}
	.p-y-lg-tp											{padding-top: 1.5em; padding-bottom: 1.5em;}
	.p-y-xl-tp											{padding-top: 2.0em; padding-bottom: 2.0em;}

	.p-t-xs-tp											{padding-top: 0.3em;}
	.p-t-sm-tp											{padding-top: 0.5em;}
	.p-t-md-tp											{padding-top: 1.0em;}
	.p-t-lg-tp											{padding-top: 1.5em;}
	.p-t-xl-tp											{padding-top: 2.0em;}

	.p-r-xs-tp											{padding-right: 0.3em;}
	.p-r-sm-tp											{padding-right: 0.5em;}
	.p-r-md-tp											{padding-right: 1.0em;}
	.p-r-lg-tp											{padding-right: 1.5em;}
	.p-r-xl-tp											{padding-right: 2.0em;}

	.p-b-xs-tp											{padding-bottom: 0.3em;}
	.p-b-sm-tp											{padding-bottom: 0.5em;}
	.p-b-md-tp											{padding-bottom: 1.0em;}
	.p-b-lg-tp											{padding-bottom: 1.5em;}
	.p-b-xl-tp											{padding-bottom: 2.0em;}

	.p-l-xs-tp											{padding-left: 0.3em;}
	.p-l-sm-tp											{padding-left: 0.5em;}
	.p-l-md-tp											{padding-left: 1.0em;}
	.p-l-lg-tp											{padding-left: 1.5em;}
	.p-l-xl-tp											{padding-left: 2.0em;}
}

/* Mobile (Landscape), Design for a width of 480px */
@media (max-width: 767px) {	
	.p-a-0-ml											{padding: 0px !important;}
	.p-x-0-ml											{padding-left: 0px !important; padding-right: 0px !important;}
	.p-y-0-ml											{padding-top: 0px !important; padding-bottom: 0px !important;}
	.p-t-0-ml											{padding-top: 0px !important;}
	.p-r-0-ml											{padding-right: 0px !important;}
	.p-b-0-ml											{padding-bottom: 0px !important;}
	.p-l-0-ml											{padding-left: 0px !important;}

	.p-a-xs-ml											{padding: 0.3em;}
	.p-a-sm-ml											{padding: 0.5em;}
	.p-a-md-ml											{padding: 1.0em;}
	.p-a-lg-ml											{padding: 1.5em;}
	.p-a-xl-ml											{padding: 2.0em;}

	.p-x-xs-ml											{padding-left: 0.3em; padding-right: 0.3em;}
	.p-x-sm-ml											{padding-left: 0.5em; padding-right: 0.5em;}
	.p-x-md-ml											{padding-left: 1.0em; padding-right: 1.0em;}
	.p-x-lg-ml											{padding-left: 1.5em; padding-right: 1.5em;}
	.p-x-xl-ml											{padding-left: 2.0em; padding-right: 2.0em;}

	.p-y-xs-ml											{padding-top: 0.3em; padding-bottom: 0.3em;}
	.p-y-sm-ml											{padding-top: 0.5em; padding-bottom: 0.5em;}
	.p-y-md-ml											{padding-top: 1.0em; padding-bottom: 1.0em;}
	.p-y-lg-ml											{padding-top: 1.5em; padding-bottom: 1.5em;}
	.p-y-xl-ml											{padding-top: 2.0em; padding-bottom: 2.0em;}

	.p-t-xs-ml											{padding-top: 0.3em;}
	.p-t-sm-ml											{padding-top: 0.5em;}
	.p-t-md-ml											{padding-top: 1.0em;}
	.p-t-lg-ml											{padding-top: 1.5em;}
	.p-t-xl-ml											{padding-top: 2.0em;}

	.p-r-xs-ml											{padding-right: 0.3em;}
	.p-r-sm-ml											{padding-right: 0.5em;}
	.p-r-md-ml											{padding-right: 1.0em;}
	.p-r-lg-ml											{padding-right: 1.5em;}
	.p-r-xl-ml											{padding-right: 2.0em;}

	.p-b-xs-ml											{padding-bottom: 0.3em;}
	.p-b-sm-ml											{padding-bottom: 0.5em;}
	.p-b-md-ml											{padding-bottom: 1.0em;}
	.p-b-lg-ml											{padding-bottom: 1.5em;}
	.p-b-xl-ml											{padding-bottom: 2.0em;}

	.p-l-xs-ml											{padding-left: 0.3em;}
	.p-l-sm-ml											{padding-left: 0.5em;}
	.p-l-md-ml											{padding-left: 1.0em;}
	.p-l-lg-ml											{padding-left: 1.5em;}
	.p-l-xl-ml											{padding-left: 2.0em;}

}

/* Mobile (Portrait), Design for a width of 320px */
@media (max-width: 479px) {
	.p-a-0-mp											{padding: 0px !important;}
	.p-x-0-mp											{padding-left: 0px !important; padding-right: 0px !important;}
	.p-y-0-mp											{padding-top: 0px !important; padding-bottom: 0px !important;}
	.p-t-0-mp											{padding-top: 0px !important;}
	.p-r-0-mp											{padding-right: 0px !important;}
	.p-b-0-mp											{padding-bottom: 0px !important;}
	.p-l-0-mp											{padding-left: 0px !important;}

	.p-a-xs-mp											{padding: 0.3em;}
	.p-a-sm-mp											{padding: 0.5em;}
	.p-a-md-mp											{padding: 1.0em;}
	.p-a-lg-mp											{padding: 1.5em;}
	.p-a-xl-mp											{padding: 2.0em;}

	.p-x-xs-mp											{padding-left: 0.3em; padding-right: 0.3em;}
	.p-x-sm-mp											{padding-left: 0.5em; padding-right: 0.5em;}
	.p-x-md-mp											{padding-left: 1.0em; padding-right: 1.0em;}
	.p-x-lg-mp											{padding-left: 1.5em; padding-right: 1.5em;}
	.p-x-xl-mp											{padding-left: 2.0em; padding-right: 2.0em;}

	.p-y-xs-mp											{padding-top: 0.3em; padding-bottom: 0.3em;}
	.p-y-sm-mp											{padding-top: 0.5em; padding-bottom: 0.5em;}
	.p-y-md-mp											{padding-top: 1.0em; padding-bottom: 1.0em;}
	.p-y-lg-mp											{padding-top: 1.5em; padding-bottom: 1.5em;}
	.p-y-xl-mp											{padding-top: 2.0em; padding-bottom: 2.0em;}

	.p-t-xs-mp											{padding-top: 0.3em;}
	.p-t-sm-mp											{padding-top: 0.5em;}
	.p-t-md-mp											{padding-top: 1.0em;}
	.p-t-lg-mp											{padding-top: 1.5em;}
	.p-t-xl-mp											{padding-top: 2.0em;}

	.p-r-xs-mp											{padding-right: 0.3em;}
	.p-r-sm-mp											{padding-right: 0.5em;}
	.p-r-md-mp											{padding-right: 1.0em;}
	.p-r-lg-mp											{padding-right: 1.5em;}
	.p-r-xl-mp											{padding-right: 2.0em;}

	.p-b-xs-mp											{padding-bottom: 0.3em;}
	.p-b-sm-mp											{padding-bottom: 0.5em;}
	.p-b-md-mp											{padding-bottom: 1.0em;}
	.p-b-lg-mp											{padding-bottom: 1.5em;}
	.p-b-xl-mp											{padding-bottom: 2.0em;}

	.p-l-xs-mp											{padding-left: 0.3em;}
	.p-l-sm-mp											{padding-left: 0.5em;}
	.p-l-md-mp											{padding-left: 1.0em;}
	.p-l-lg-mp											{padding-left: 1.5em;}
	.p-l-xl-mp											{padding-left: 2.0em;}
}


/* ---------------------------------------------------------------------------------------------------------------------------------------
1100 - Border
--------------------------------------------------------------------------------------------------------------------------------------- */

/* 1100 - Rounded Corner
/* ------------------------------------------------------------ */
.rounded-a-xs											{border-radius: 3px;}
.rounded-a-sm											{border-radius: 6px;}
.rounded-a-md											{border-radius: 10px;}
.rounded-a-lg											{border-radius: 15px;}
.rounded-a-xl											{border-radius: 20px;}

.rounded-t-xs											{border-top-left-radius: 3px; border-top-right-radius: 3px;}
.rounded-t-sm											{border-top-left-radius: 6px; border-top-right-radius: 6px;}
.rounded-t-md											{border-top-left-radius: 10px; border-top-right-radius: 10px;}
.rounded-t-lg											{border-top-left-radius: 15px; border-top-right-radius: 15px;}
.rounded-t-xl											{border-top-left-radius: 20px; border-top-right-radius: 20px;}

.rounded-b-xs											{border-bottom-left-radius: 3px; border-bottom-right-radius: 3px;}
.rounded-b-sm											{border-bottom-left-radius: 6px; border-bottom-right-radius: 6px;}
.rounded-b-md											{border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;}
.rounded-b-lg											{border-bottom-left-radius: 15px; border-bottom-right-radius: 15px;}
.rounded-b-xl											{border-bottom-left-radius: 20px; border-bottom-right-radius: 20px;}


/* Tablet (Portrait), Design for a width of 768px */
@media (max-width: 1023px) {	
	.rounded-a-xs-tp										{border-radius: 3px;}
	.rounded-a-sm-tp										{border-radius: 6px;}
	.rounded-a-md-tp										{border-radius: 10px;}
	.rounded-a-lg-tp										{border-radius: 15px;}
	.rounded-a-xl-tp										{border-radius: 20px;}

	.rounded-t-xs-tp										{border-top-left-radius: 3px; border-top-right-radius: 3px;}
	.rounded-t-sm-tp										{border-top-left-radius: 6px; border-top-right-radius: 6px;}
	.rounded-t-md-tp										{border-top-left-radius: 10px; border-top-right-radius: 10px;}
	.rounded-t-lg-tp										{border-top-left-radius: 15px; border-top-right-radius: 15px;}
	.rounded-t-xl-tp										{border-top-left-radius: 20px; border-top-right-radius: 20px;}

	.rounded-b-xs-tp										{border-bottom-left-radius: 3px; border-bottom-right-radius: 3px;}
	.rounded-b-sm-tp										{border-bottom-left-radius: 6px; border-bottom-right-radius: 6px;}
	.rounded-b-md-tp										{border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;}
	.rounded-b-lg-tp										{border-bottom-left-radius: 15px; border-bottom-right-radius: 15px;}
	.rounded-b-xl-tp										{border-bottom-left-radius: 20px; border-bottom-right-radius: 20px;}	
}

/* Mobile (Landscape), Design for a width of 480px */
@media (max-width: 767px) {	
	.rounded-a-xs-ml										{border-radius: 3px;}
	.rounded-a-sm-ml										{border-radius: 6px;}
	.rounded-a-md-ml										{border-radius: 10px;}
	.rounded-a-lg-ml										{border-radius: 15px;}
	.rounded-a-xl-ml										{border-radius: 20px;}

	.rounded-t-xs-ml										{border-top-left-radius: 3px; border-top-right-radius: 3px;}
	.rounded-t-sm-ml										{border-top-left-radius: 6px; border-top-right-radius: 6px;}
	.rounded-t-md-ml										{border-top-left-radius: 10px; border-top-right-radius: 10px;}
	.rounded-t-lg-ml										{border-top-left-radius: 15px; border-top-right-radius: 15px;}
	.rounded-t-xl-ml										{border-top-left-radius: 20px; border-top-right-radius: 20px;}

	.rounded-b-xs-ml										{border-bottom-left-radius: 3px; border-bottom-right-radius: 3px;}
	.rounded-b-sm-ml										{border-bottom-left-radius: 6px; border-bottom-right-radius: 6px;}
	.rounded-b-md-ml										{border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;}
	.rounded-b-lg-ml										{border-bottom-left-radius: 15px; border-bottom-right-radius: 15px;}
	.rounded-b-xl-ml										{border-bottom-left-radius: 20px; border-bottom-right-radius: 20px;}		

}

/* Mobile (Portrait), Design for a width of 320px */
@media (max-width: 479px) {
	.rounded-a-xs-mp										{border-radius: 3px;}
	.rounded-a-sm-mp										{border-radius: 6px;}
	.rounded-a-md-mp										{border-radius: 10px;}
	.rounded-a-lg-mp										{border-radius: 15px;}
	.rounded-a-xl-mp										{border-radius: 20px;}

	.rounded-t-xs-mp										{border-top-left-radius: 3px; border-top-right-radius: 3px;}
	.rounded-t-sm-mp										{border-top-left-radius: 6px; border-top-right-radius: 6px;}
	.rounded-t-md-mp										{border-top-left-radius: 10px; border-top-right-radius: 10px;}
	.rounded-t-lg-mp										{border-top-left-radius: 15px; border-top-right-radius: 15px;}
	.rounded-t-xl-mp										{border-top-left-radius: 20px; border-top-right-radius: 20px;}

	.rounded-b-xs-mp										{border-bottom-left-radius: 3px; border-bottom-right-radius: 3px;}
	.rounded-b-sm-mp										{border-bottom-left-radius: 6px; border-bottom-right-radius: 6px;}
	.rounded-b-md-mp										{border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;}
	.rounded-b-lg-mp										{border-bottom-left-radius: 15px; border-bottom-right-radius: 15px;}
	.rounded-b-xl-mp										{border-bottom-left-radius: 20px; border-bottom-right-radius: 20px;}			
}

/* ---------------------------------------------------------------------------------------------------------------------------------------
1200 - Text
--------------------------------------------------------------------------------------------------------------------------------------- */

/* 1200 - Text Alignment
/* ------------------------------------------------------------ */
.align-l												{text-align: left;}
.align-c												{text-align: center;}
.align-r												{text-align: right;}

/* Tablet (Portrait), Design for a width of 768px */
@media (max-width: 1023px) {	
	.align-l-tp											{text-align: left;}
	.align-c-tp											{text-align: center;}
	.align-r-tp											{text-align: right;}	
}

/* Mobile (Landscape), Design for a width of 480px */
@media (max-width: 767px) {	
	.align-l-ml											{text-align: left;}
	.align-c-ml											{text-align: center;}
	.align-r-ml											{text-align: right;}		
	
}

/* Mobile (Portrait), Design for a width of 320px */
@media (max-width: 479px) {	
	.align-l-mp											{text-align: left;}
	.align-c-mp											{text-align: center;}
	.align-r-mp											{text-align: right;}			
}
