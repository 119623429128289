/* Countdown Clock
/* ------------------------------------------------------------ */
.container-countdown 									      {font-family: 'Roboto Condensed', sans-serif; margin: 0 auto; display: block; width: 320px;}

.container-countdown .title								  {font-size: 28px; font-weight: bold; text-transform: uppercase; color: #ff0505; position: relative; overflow: hidden; margin-bottom: 12px; text-align: center; line-height: 1.3em;;}
.container-countdown .title span                	{display: inline-block; position: relative;} 
.container-countdown .title span:before,
.container-countdown .title span:after                  {content: ""; position: absolute; height: 14px; border-bottom: 1px solid #f26622; top: -4px; width: 600px;}
.container-countdown .title span:before                 {right: 100%; margin-right: 4px;}
.container-countdown .title span:after                  {left: 100%; margin-left: 4px;}

.container-countdown .countdown_digit					{display: flex; flex-direction: row; text-align: center; flex-wrap: nowrap; justify-content: space-evenly;}
.container-countdown .digits,
.container-countdown .desc								{display: block; font-weight: bold; color: #044f7a;}
.container-countdown .digits							{font-size: 42px;}
.container-countdown .desc								{font-size: 17px; text-transform: uppercase; margin-top: 4px;}

.container-countdown .divider							{font-size: 42px; font-weight: bold; color: #044f7a;}
