/* Thermometer
/* ------------------------------------------------------------ */
.container-thermometer									{margin: 0 auto; display: block; width: 700px;}
.container-thermometer .container                       {position: relative; overflow: hidden;}
.container-thermometer .container, 
.container-thermometer .bg                              {height: 30px;}
.container-thermometer .meter,
.container-thermometer .current                         {height: 29px;}                        
.container-thermometer .current                         {padding-top: 2px; line-height: 26px;}
.container-thermometer .meter               
.container-thermometer .bg, 
.container-thermometer .meter                           {position: absolute; left: 0px; top: 0px;}
.container-thermometer .current                         {position: absolute; right: 0px;}
.container-thermometer .container                       {border: 1px solid rgb(206, 234, 248);}
.container-thermometer .bg                              {background: rgb(206, 234, 248); width: 100%;}
.container-thermometer .meter                           {background:rgb(13, 49, 252); width: 0%; transition: width 3.5s;}

.container-thermometer .current                         {text-align: right; padding-right: 5px; color:rgb(255, 255, 255); font-weight: bold;}

.container-thermometer .goal                            {padding-top: 5px; text-align: right; color:rgb(13, 49, 252); font-weight: bold;}
