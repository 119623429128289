@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,400;0,700;1,400;1,700&display=swap');
/* ---------------------------------------------------------------------------------------------------------------------------------------
0001 - Global
--------------------------------------------------------------------------------------------------------------------------------------- */
body												    	  {font-family: 'Roboto', sans-serif; font-size: 16px; position: relative;}
.tablet, .show-mobile							  {display: none;}
.desktop												    {display: block;}
.wrapper, .main-wrapper, 
.contents-wrapper, .form-wrapper 	  {margin: 0 auto; box-sizing:border-box; -moz-box-sizing:border-box; -webkit-box-sizing:border-box;}
.wrapper												    {width: 100%;}
.main-wrapper											  {max-width: 1000px;}
.contents-wrapper										{max-width: 768px;}
.form-wrapper											  {max-width: 420px; max-width: 100%;}

.main-wrapper											  {background: #ffffff;}


/* ---------------------------------------------------------------------------------------------------------------------------------------
0002 - Typography
--------------------------------------------------------------------------------------------------------------------------------------- */
.gf-roboto											  	{font-family: 'Roboto', sans-serif;}
.gf-roboto-condensed								{font-family: 'Roboto Condensed', sans-serif;}

h1, h2, h3												  {font-family: 'Roboto', sans-serif;}
h1														      {font-size: 2.2em; font-weight: bold; line-height: 1.2em;} 

p, li											      		{margin-bottom: 1.5em; line-height: 1.5em;}
li													      	{list-style: disc; margin-left: 2em;}
a 													      	{color: #3aa4ff; text-decoration: underline;}

/* ---------------------------------------------------------------------------------------------------------------------------------------
0003 - Body
--------------------------------------------------------------------------------------------------------------------------------------- */
#container-contents                 {margin: 30px 0 30px 210px;}
#container-contents p               {margin-bottom: 1.5em; line-height: 1.5em; font-size: 1.1rem;}
#container-contents .intro          {font-size: 16px;}

/* ---------------------------------------------------------------------------------------------------------------------------------------
0006 - Plugin
--------------------------------------------------------------------------------------------------------------------------------------- */
.lightbox                           {position: fixed; width: 100vw; height: 100vh; left: 0px; top: 0px; z-index: 999;}

/* ---------------------------------------------------------------------------------------------------------------------------------------
1000 - Common
--------------------------------------------------------------------------------------------------------------------------------------- */
.align-right											  {text-align: right;}
.banner												    	{width: 100%;}
.box-size 											  	{-moz-box-sizing:border-box; -webkit-box-sizing:border-box; box-sizing:border-box;}
.button													    {cursor: hand; cursor: pointer;}
.center													    {text-align: center;}
.clear:after   	  									{display:block; clear:both; content:'.'; visibility:hidden; height:0;}
.container												  {position: relative;}
.cross-out												  {text-decoration: line-through;} 
.emphasize												  {font-weight: bold; text-transform: uppercase;}
.hidden													    {display: none;}
.illustration											  {max-width: 100%;}
.illustration-left									{max-width: 50%; float: left; margin: 0 1.3em 1.3em 0;}
.illustration-right									{max-width: 50%; float: right; margin: 0 0 1.3em 1.3em;}
.indent													    {text-indent: 1em;}
.inline													    {display: inline-block;}
.invisible												  {visibility: hidden;}
.large													    {font-size: 1.2em;}
.left, .right											  {-moz-box-sizing:border-box; -webkit-box-sizing:border-box; box-sizing:border-box;} 
.left 												  	  {float: left;}
.nobr													      {white-space: nowrap;}
.panel													    {display: none;}
.panel.active											  {display: block;}
.right													    {float: right;}
.rounded-top											  {border-top-left-radius: 6px; border-top-right-radius: 6px;}
.rounded-bottom											{border-bottom-left-radius: 6px; border-bottom-right-radius: 6px;}
.rounded												    {border-radius: 3px;}
.shadow													    {-webkit-box-shadow: 0 0 15px rgba(30, 30, 30, .75); -moz-box-shadow: 0 0 15px rgba(30, 30, 30, .75); box-shadow: 0 0 15px rgba(30, 30, 30, .75);}
.shadow-bottom-right								{-moz-box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, .15); -webkit-box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, .15); box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, .15);}
.shadow-3sides											{-webkit-box-shadow: 0px 8px 10px rgba(100, 100, 100, .75), -10px 8px 15px rgba(100, 100, 100, .75), 10px 8px 15px rgba(100, 100, 100, .75); -moz-box-shadow: 0px 8px 10px rgba(100, 100, 100, .75), -10px 8px 15px rgba(100, 100, 100, .75), 10px 8px 15px rgba(100, 100, 100, .75); box-shadow: 0px 8px 10px rgba(100, 100, 100, .75), -10px 8px 15px rgba(100, 100, 100, .75), 10px 8px 15px rgba(100, 100, 100, .75);}
.shadow-3sides-dark									{-webkit-box-shadow: 0px 8px 10px rgba(45, 45, 45, .25), -10px 8px 15px rgba(45, 45, 45, .25), 10px 8px 15px rgba(45, 45, 45, .25); -moz-box-shadow: 0px 8px 10px rgba(45, 45, 45, .25), -10px 8px 15px rgba(45, 45, 45, .25), 10px 8px 15px rgba(45, 45, 45, .25); box-shadow: 0px 8px 10px rgba(45, 45, 45, .25), -10px 8px 15px rgba(45, 45, 45, .25), 10px 8px 15px rgba(45, 45, 45, .25);}
.shadow-3sides-light								{-webkit-box-shadow: 0px 8px 10px rgba(100, 100, 100, .35), -10px 8px 15px rgba(100, 100, 100, .35), 10px 8px 15px rgba(100, 100, 100, .35); -moz-box-shadow: 0px 8px 10px rgba(100, 100, 100, .35), -10px 8px 15px rgba(100, 100, 100, .35), 10px 8px 15px rgba(100, 100, 100, .35); box-shadow: 0px 8px 10px rgba(100, 100, 100, .35), -10px 8px 15px rgba(100, 100, 100, .35), 10px 8px 15px rgba(100, 100, 100, .35);}
.shadow-inner-top										{-moz-box-shadow: inset 0 20px 20px -20px #000000; -webkit-box-shadow: inset 0 20px 20px -20px #000000; box-shadow: inset 0 20px 20px -20px #000000;}
.text-shadow											  {text-shadow: black 0.3em 0.3em 0.5em;}
.upper													    {text-transform: uppercase;}
.v-center												    {position: relative; top: 50%; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%); transform: translateY(-50%);}
.x-large												    {font-size: 1.4em;}


/* ---------------------------------------------------------------------------------------------------------------------------------------
2000 - Animation
--------------------------------------------------------------------------------------------------------------------------------------- */
.fade-in 											    	{animation: fadeIn ease 0.4s; -webkit-animation: fadeIn ease 0.4s; -moz-animation: fadeIn ease 0.4s; -o-animation: fadeIn ease 0.4s; -ms-animation: fadeIn ease 0.4s;}


/* ---------------------------------------------------------------------------------------------------------------------------------------
0005 - Responsive
--------------------------------------------------------------------------------------------------------------------------------------- */


/* Tablet (Portrait), Design for a width of 768px */
@media (max-width: 1023px) {	
	body 													    {min-width: 768px !important;}	
	.desktop, .show-mobile						{display: none;}
	.tablet													  {display: block;}							
	.main-wrapper											{width: 768px;}
	.contents-wrapper									{width: 720px;}			
	.form-wrapper											{width: 700px;}		
}

/* Mobile (Landscape), Design for a width of 480px */
@media (max-width: 767px) {
	html													    {background: none;}
	body 													    {min-width: 480px !important; font-size: 15px;}
	.desktop, .tablet									{display: none;}
	.show-mobile											{display: block;}		
	.main-wrapper											{width: 480px;}
	.contents-wrapper									{width: 440px;}	
	.form-wrapper											{width: 420px;}	
		
}

/* Mobile (Portrait), Design for a width of 320px */
@media (max-width: 479px) {
	body 													    {min-width: 320px !important;}
	.main-wrapper											{width: 100%;}
	.contents-wrapper									{width: 94%;}
	.form-wrapper											{width: 94%;}	
	
}


