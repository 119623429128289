.gallery-item   {

  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
  width: 100%;
  background-color: #fafafa;
  border:1px solid #cccccc;
  color: #030303;
  margin: 15px;
  font-weight: bold;

}

.gallery-item h3 {
  color: #480ece;  
  margin-bottom: 10px;
}

#gallery-2 p:last-child {margin-bottom: 0px;}
